// Setup Appsignal
import Appsignal from '@appsignal/javascript'
import {plugin} from '@appsignal/plugin-window-events'

const appsignalFrontendApiKeyHeader = document.head.querySelector('[name~=appsignal_frontend_api_key][content]')
const appRevisionHeader = document.head.querySelector('[name~=app_revision][content]')

const appsignal = new Appsignal({
  key: appsignalFrontendApiKeyHeader === null ? '' : appsignalFrontendApiKeyHeader.content,
  revision: appRevisionHeader === null ? '' : appRevisionHeader.content
})

appsignal.use(plugin())

// Setup Stimulus
import {Application} from "@hotwired/stimulus"
import TableSearchController from './controllers/table_search_controller'
import DateFiltersController from './controllers/date_filters_controller'
import RangeFiltersController from "./controllers/range_filters_controller"
import RefreshController from "./controllers/refresh_controller"


window.Stimulus = Application.start()
Stimulus.register("table-search", TableSearchController)
Stimulus.register("date-filters", DateFiltersController)
Stimulus.register("range-filters", RangeFiltersController)
Stimulus.register("refresh", RefreshController)

// Load Rails UJS
import Rails from "@rails/ujs"

if (!window._rails_loaded) {
  Rails.start()
}

// Use Chartkick for OKR dashboard
import "chartkick/chart.js"
import {Chart} from 'chart.js';

// Make annotations for charts
import annotationPlugin from 'chartjs-plugin-annotation';

Chart.register(annotationPlugin);
