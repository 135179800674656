import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tableBody", "searchInput", "clearButton"];

  connect() {
    this.search(this.searchInputTarget.value);
  }

  handleKeyUp(event) {
    if(event.key == "Escape") {
      event.target.blur();
    }

    this.search(event.target.value);
  }

  search(searchQuery = '') {
    this.resetTableRows();
    this.resetClearButton();

    if(searchQuery.length > 0) {
      this.displayMatches(searchQuery);
      this.show(this.clearButtonTarget);
    } else {
      this.clear();
    }
  }

  clear() {
    this.searchInputTarget.value = ''
    this.resetTableRows();
    this.resetClearButton();

    return false;
  }

  resetTableRows() {
    this.tableBodyTarget.querySelectorAll('tr').forEach(this.show);
  }

  resetClearButton() {
    this.hide(this.clearButtonTarget);
  }

  show(element) {
    element.classList.remove('hidden');
  }

  hide(element) {
    element.classList.add('hidden');
  }

  displayMatches(searchQuery) {
    this.tableBodyTarget.querySelectorAll('tr').forEach((row) => {
      if(!row.textContent.toLowerCase().includes(searchQuery.toLowerCase())) {
        this.hide(row);
      }
    });
  }
}
