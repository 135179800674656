import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["fromDate", "toDate", "yearSelection", "searchQuery", "clearButton"]

  prefillDatepickers() {
    if (this.yearSelectionTarget.value.length > 0) {
      this.fromDateTarget.value = `${this.yearSelectionTarget.value}-01-01`
      this.toDateTarget.value = `${this.yearSelectionTarget.value}-12-31`
      this.showClearButton()
    }
  }

  clearYearSelector() {
    if (this.fromDateTarget.value.length > 0 || this.toDateTarget.value.length > 0) {
      this.yearSelectionTarget.value = ""
      this.showClearButton()
    }
  }

  handleSearchInput() {
    if (this.searchQueryTarget.value.length > 0) {
      this.showClearButton()
    }
  }

  showClearButton() {
    this.clearButtonTarget.classList.remove('invisible');
  }
}
