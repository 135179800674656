import noUiSlider from 'nouislider';
import 'nouislider/dist/nouislider.css';
import './sliders.css';

export default function initializeSlider(slider, minInput, maxInput) {
  // minValue and maxValue are existing min and max of a certain column of a certain table in database
  // e.g. BookeTime.minimum(:sum_total)
  // minInput and maxInput are values provided by user for filtering in input fields
  let minValue = parseInt(minInput.dataset.minValue)
  let maxValue = parseInt(maxInput.dataset.maxValue)

  // values for sliders in CHF are stored in database as cents. But we want to display francs, not cents
  // that's why we devide range values by 100 before creating a slider
  if (slider.id !== 'booked_hours_slider') {
    maxValue = maxValue / 100
    minValue = minValue / 100
  }

  // if user entered desired values in input fields, slider should show them between redisplays
  // if no values provided by user, initial values shown: max and min existing values
  const start = minInput.value && minInput.value !== minValue ? minInput.value : minValue
  const end = maxInput.value && maxInput.value !== maxValue ? maxInput.value : maxValue

  noUiSlider.create(slider, {
    start: [start, end],
    connect: true,
    range: {
        'min': minValue,
        'max': maxValue,
    }
  });

  // update input values
  slider.noUiSlider.on('update', function (values, handle) {
    const value = values[handle];

    if (handle) {
      maxInput.value = Math.round(value);
    } else {
      minInput.value = Math.round(value);
    }
  });

  minInput.addEventListener("change", function () {
    slider.noUiSlider.set([this.value, null]);
  });

  maxInput.addEventListener("change", function () {
    slider.noUiSlider.set([null, this.value])
  })
}
