import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  timeout = null;

  connect() {
    this.timeout = setTimeout("window.location.reload();", 600000);
  }

  disconnect() {
    if (this.timeout !== null) {
      clearTimeout(this.timeout);
    }
  }
}
