import { Controller } from "@hotwired/stimulus";
import initializeSlider from "../components/sliders";

export default class extends Controller {
  static targets = ["bookedHoursSlider", "minBookedHours", "maxBookedHours",
    "performanceSlider", "minPerformance", "maxPerformance", "estimatedPerformanceSlider",
    "minEstimatedPerformance", "maxEstimatedPerformance", "aggregatedInvoicesSlider",
    "minAggregatedInvoices", "maxAggregatedInvoices"]

  connect() {
    this.handleSliders()
  }

  handleSliders() {
    // booked hours slider
    initializeSlider(this.bookedHoursSliderTarget, this.minBookedHoursTarget, this.maxBookedHoursTarget)

    // effective hourly rate slider
    initializeSlider(this.performanceSliderTarget, this.minPerformanceTarget, this.maxPerformanceTarget)

    // estimated effective hourly rate slider
    initializeSlider(this.estimatedPerformanceSliderTarget, this.minEstimatedPerformanceTarget, this.maxEstimatedPerformanceTarget)

    // invoices total
    initializeSlider(this.aggregatedInvoicesSliderTarget, this.minAggregatedInvoicesTarget, this.maxAggregatedInvoicesTarget)
  }
}
